import { checkValue } from 'src/components/common/helper';

const getTags = (data) => {
  if (checkValue(data, true)) {
    return data.map((tag) => {
      if (tag?._type === 'visibleTag') {
        return {
          prefLabel: tag?.tag,
        };
      }
      if (tag?._type === 'geoTag') {
        return {
          prefLabel:
            tag?.targetType === 'country'
              ? tag?.country
              : tag?.targetType === 'province_state'
              ? tag?.province
              : tag?.city,
        };
      }
    });
  } else {
    return [];
  }
};

export default getTags;
