import { Store, ServerFactories } from 'bernie-plugin-mobx';
import { NOOP_LOGGER, Logger } from 'bernie-logger';
import { Request } from 'bernie-http';
import { ClientLogger } from 'bernie-client';

import { BexApiSource, CpCmsLiteSource, SanityApiSource } from '../sources';
import { getBexApiSdk } from '../sources/get-bex-api-sdk';
import { BexApiStore } from './bex-api-store';
import { CpCmsLiteStore } from './cp-cmslite-store';
import { parse } from 'url';
import { getSanityApiSdk } from 'src/sources/services/sanity.service';
import { SanityStore } from './cp-sanity-store';
import { config } from 'src/config/config';
import { checkValue } from 'src/components/functions';

const disallowedHeaders = [/accept/i, /content-length/i, /content-type/i, /credentials/i, /host/i];

export { BexApiStore, CpCmsLiteStore, SanityStore };

export const buildServerSideBexApiSource = (url: string, logger: Logger, request: Request): BexApiSource => {
  const headers = {
    ...request.headers,
    'Device-User-Agent-ID': request?.context?.deviceId,
  };

  Object.keys(headers).forEach((header) => {
    disallowedHeaders.forEach((disallowed) => {
      if (header.match(disallowed) !== null) {
        delete headers[header];
      }
    });
  });

  const host = parse(url).host;
  const api = getBexApiSdk({ host, ...headers }, url);

  return new BexApiSource(api, logger);
};

export const buildSanityApiSource = (request?: Request): SanityApiSource => {
  const sanityGraphqlUrl =
    checkValue(request) && checkValue(request?.query?.islive) && request?.query?.islive === '1'
      ? config.sanityDraftEndPoint
      : config.sanityEndPoint;
  const api = getSanityApiSdk(sanityGraphqlUrl, 'no-cache');
  return new SanityApiSource(api, ClientLogger.getLoggerWithIdentifier('sanity.source'));
};

export const stores: ServerFactories = {
  bexapistore: ({ request, config, logger }): Store =>
    new BexApiStore(buildServerSideBexApiSource(config.platform.bexApi.url, logger, request), {}, NOOP_LOGGER),
  cpcmslitefeed: (): Store => new CpCmsLiteStore(new CpCmsLiteSource(), {}, NOOP_LOGGER),
  sanityStore: ({ request }): Store => new SanityStore(buildSanityApiSource(request), {}, NOOP_LOGGER),
};
