import { useStores } from 'src/components/common/utils/useStore';
import { checkValue } from 'src/components/functions';
import { config } from 'src/config/config';

const campaignTemplates = config.campaignTemplates;

export const getTemplateType = (template: string) => {
  let templateMapper = undefined;
  campaignTemplates.forEach((value: any) => {
    if (checkValue(templateMapper)) {
      return;
    }

    value?.templatesPriFix.forEach((v: string) => {
      if (template?.indexOf(v) > -1) {
        templateMapper = {
          templateType: value?.campaign,
          template: v,
          templatesPriFix: template
        };
        return;
      }
    });
  });

  return templateMapper;
};

export const getStore = () => {
  const {
    cpcmslitefeed: { cpCmsLitePageData, spotlightPageData, conciergePageData },
    sanityStore: { sanityPageData },
  } = useStores();

  return checkValue(cpCmsLitePageData)
    ? cpCmsLitePageData
    : checkValue(spotlightPageData)
      ? spotlightPageData
      : checkValue(conciergePageData)
        ? conciergePageData
        : checkValue(sanityPageData)
          ? sanityPageData
        : undefined;
}