import { c, xorDecrypt } from './encrypt';

export const config = {
  bexApiUrl: 'https://weaver-loom-bex.rcp.partnerexperiences.test.exp-aws.net/graphql',
  caps_api: 'https://www.expedia.com/capsapi/template-cplite/',
  magazine_api: 'https://www.expedia.com/',
  ga_account: 'UA-167763302-1',
  media_store_url: '/media-studio/{page_name}',
  meso_concierge: {
    host: 'https://www.expedia.com/capsapi/template-cplite/',
    ga: 'UA-167763302-1',
    api: '/capsapi/',
    urlPattern: '/prepare/{feedName}',
    localStorageKey: 'concierge_wish_list',
  },
  meso3_sponsorbanner: {
    localStorageKey: 'meso3sponsorbanner',
  },
  price_format: {
    en_us: '${}',
    en_gb: '£{}',
    it_it: '€ {}',
    de_de: '{} €',
    fr_fr: '{} €',
    en_ca: 'CA ${}',
    fr_ca: '{} $ CA',
  },
  captureSiteKey: '6Lc2uLEbAAAAAOn038AAmm3BZsKykFzEx5nmmTJ0',
  campaignTemplates: [
    {
      campaign: 'spotlight',
      templatesPriFix: [
        'articleTemplate',
        'galleryTemplate',
        'itineraryTemplate',
        'listicleTemplate',
        'quizTemplateNew',
        'conciergeTemplateNew',
      ],
    },
    { campaign: 'cplite', templatesPriFix: ['homepage', 'ttnqOneoff'] },
    { campaign: 'concierge', templatesPriFix: ['conciergeHome'] },
    { campaign: 'mirror', templatesPriFix: ['mirrorSocialTemplate'] },
  ],
  vrboHotelSearchUrl: 'https://www.vrbo.com/serp/g',
  moduleHeadingSizes: [
    {
      type: 'default',
      fontSize: null,
      lineHeight: null,
      fontSizeMobile: null,
      lineHeightMobile: null,
    },
    {
      type: 'large',
      fontSize: 90,
      lineHeight: 1,
      fontSizeMobile: 50,
      lineHeightMobile: 1,
    },
    {
      type: 'medium',
      fontSize: 42,
      lineHeight: 56,
      fontSizeMobile: 40,
      lineHeightMobile: 48,
    },
  ],
  staticPlaceholderImage: 'https://mesocp3.azureedge.net/media/1w4lekki/static-image.png',
  googleMapsApiKey: xorDecrypt(c.gk, c.k),
  sanityEndPoint: 'https://nxpteyfv.api.sanity.io/v2023-08-01/graphql/media-studio/brand?perspective=published',
  sanityDraftEndPoint:
    'https://nxpteyfv.api.sanity.io/v2023-08-01/graphql/media-studio/brand?perspective=previewDrafts',
  sanityToken: xorDecrypt(c.sk, c.k),
  themeTypes: ['Meso-Light', 'Meso-Dark', 'Brand-Light', 'Brand-Dark'],
  defaultThemeType: 'Meso-Light',
  sanityProjectId: 'nxpteyfv',
  sanityDataSet: 'media-studio',
  sanityApiVersion: '2023-08-01',
  default_EN_US_lozenge_description: `This content was paid for by [partnerName] and created by Expedia Group Media Studio. The editorial staff of Expedia Group companies had no role in this content.`,
};
