import { AllBusaHomeTemplate, AllBusaSubTemplate, AllListicleBlock } from 'src/common/__generated__/sanity/types';
import { groupBy } from 'lodash';
import { checkValue } from 'src/components/functions';
import { sanityLocaleMap } from '../helpers/sanityLocaleMap';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';

export class VisitUsaTemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  public async fetchVisitUsaHomeTemplate(
    partner: string,
    templateName: string,
    pagelang: string,
    isDummyData: boolean
  ): Promise<any> {
    const variables: AllBusaHomeTemplate.Variables = {
      whereBusaHomeTemplate: {
        pageUrl: {
          matches: templateName,
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const allListicleBlockVariables: AllListicleBlock.Variables = {
      where: {
        _type: { eq: 'listicleBlock' },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const data = await this.sanityApiSource.allBusaHomeWithListicleBlocksRequest(
      variables,
      allListicleBlockVariables,
      partner
    );

    const shuffle = (arr) => {
      if (Array.isArray(arr) && arr.length > 0) {
        const initialArr = [...arr];
        let currentIndex = initialArr.length;
        while (currentIndex != 0) {
          const randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          [initialArr[currentIndex], initialArr[randomIndex]] = [initialArr[randomIndex], initialArr[currentIndex]];
        }

        return initialArr;
      }
      return null;
    };

    const getThreeUpCardsCommonDetails = (key: string, modules, ctaUrlFilters) => {
      const regex = /[ _\-/+]/g;
      const threeUpCardDetails = modules.find((el) => {
        if (el._type === 'threeUpCards' && el?.cta?.ctaUrl && key) {
          return el.cta.ctaUrl.toLowerCase().replace(regex, '').includes(key.toLowerCase().replace(regex, ''));
        }
      });
      if (threeUpCardDetails) {
        return {
          ...threeUpCardDetails,
          cta: {
            ...threeUpCardDetails.cta,
            ctaUrl:
              Array.isArray(ctaUrlFilters) && ctaUrlFilters.length > 0
                ? `${threeUpCardDetails.cta.ctaUrl}?filter=${ctaUrlFilters.join(',')}`
                : threeUpCardDetails.cta.ctaUrl,
          },
        };
      }
      return '';
    };

    const alterThreeUpCards = (mainContent, updatedThreeUpCards) => {
      if (!updatedThreeUpCards) return mainContent;
      const updatedThreeUpCardsWithDetails = updatedThreeUpCards.filter((el) => el._type);
      const modulesExceptThreeUpCards = mainContent.filter((el) => el._type !== 'threeUpCards');
      const filterModuleIndex = modulesExceptThreeUpCards.findIndex((el) => el._type === 'filterModule');
      if (filterModuleIndex !== -1) {
        return [
          ...modulesExceptThreeUpCards.slice(0, filterModuleIndex + 1),
          ...updatedThreeUpCardsWithDetails,
          ...modulesExceptThreeUpCards.slice(filterModuleIndex + 1),
        ];
      }

      return [...modulesExceptThreeUpCards, ...updatedThreeUpCardsWithDetails];
    };

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: [...sanityDummyData.content],
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(data) && checkValue(data, true) && checkValue(data?.[0]?.data?.allBusaHomeTemplate)) {
        const allBusaItem = data?.[0]?.data?.allBusaHomeTemplate?.[0];
        const allListicleBlock = data?.[1]?.data?.allListicleBlock;
        const groupedByCity =
          Array.isArray(allListicleBlock) && allListicleBlock.length > 0
            ? groupBy(allListicleBlock, (listicleObj) => listicleObj?.city)
            : null;
        const threeCitiesFromShuffledCities = groupedByCity ? shuffle(Object.keys(groupedByCity)).slice(0, 3) : null;

        const updatedThreeUpModules = threeCitiesFromShuffledCities
          ? Object.keys(groupedByCity)
              .filter((city) => threeCitiesFromShuffledCities.includes(city))
              .reduce((obj, city) => {
                const shuffledBlocks = shuffle(groupedByCity?.[city]).slice(0, 3);
                const ctaUrlFilters =
                  Array.isArray(shuffledBlocks) && shuffledBlocks.length > 0
                    ? shuffledBlocks.map((el) => el?.filterTile?._rev)
                    : [];
                obj[city] = {
                  ...getThreeUpCardsCommonDetails(city, allBusaItem.modules, ctaUrlFilters),
                  cards: shuffledBlocks.map((el) => ({
                    ...el,
                    tags: el?.filterTile?.title ? [el.filterTile.title] : '',
                  })),
                };
                return obj;
              }, {})
          : null;

        const { modules, ...rest } = allBusaItem;

        // Hero Setting Formatted
        const heroModule = checkValue(allBusaItem?.heroSetting)
          ? {
              module: allBusaItem?.heroSetting._type,
              dataFeed: FeedType.Sanity,
              moduleType: 'hero',
              ...allBusaItem?.heroSetting,
            }
          : null;

        // Module Formatted
        const formattedModules = alterThreeUpCards(
          allBusaItem?.modules,
          updatedThreeUpModules ? Object.values(updatedThreeUpModules) : null
        )?.map((module) => ({
          module: module?.['_type'],
          ...module,
          dataFeed: FeedType.Sanity,
        }));

        const finalFormattedData = {
          ...rest,
          content: checkValue(heroModule) ? [heroModule, ...formattedModules] : formattedModules,
          allListicleBlock,
          //allFilterTile,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          removeModuleSpacings: true,
          ignoreTextTruncate: true,
          defaultTagByLocalization: true, // to get the basic 1up default tag by localization
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }

  public async fetchVisitUsaSubTemplate(   
    feedName: string,
    pagelang = 'en-ca',    
    isDummyData: boolean,
    busaFilter: string
  ): Promise<any> {
    //"(?i)las[-\\s]?vegas"  //"(?i)miami"
    // const cityMatcher = '(?i)' + feedName.toLocaleLowerCase().replaceAll('-', ' ').replaceAll(' ', '[-\\s]?') + '';
    const busaSubTemplateVariables: AllBusaSubTemplate.Variables = {
      where1: {
        pageUrl: {
          matches: feedName?.replaceAll('-%20-', '-'),
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
      // where2: {
      //   city: {
      //     matches: "(?i)las[-\\s]?vegas"
      //   },
      //   filterTile: {
      //     _rev:{
      //       in:["mKpyCFQ2iRi9Wmw5eaA2E9","ZeT46SM6na5ut2UCLMxmcy","ZeT46SM6na5ut2UCLMwZxI"]
      //     }
      //   }
      // }
    };

    const filterTile = checkValue(busaFilter)
      ? {
          _rev: {
            in: busaFilter.split(','),
          },
        }
      : {};

    const allListicleBlockVariables: AllListicleBlock.Variables = {
      where: {
        _type: { eq: 'listicleBlock' },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
        filterTile: filterTile,
      },
    };

    const data = await this.sanityApiSource.allBusaSubWithListicleFilterRequest(
      busaSubTemplateVariables,
      allListicleBlockVariables,
      'busa-sub-page'
    );

    //const data = await this.sanityApiSource.allBusaSubTemplate(busaSubTemplateVariables, partner);

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: [...sanityDummyData.content],
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(data) && checkValue(data, true) && checkValue(data?.[0]?.data?.allBusaSubTemplate)) {
        const allBusaItem = data?.[0]?.data?.allBusaSubTemplate?.[0];
        const listicleBlocks = data?.[1].data?.allListicleBlock;

        // listicleBlocks?.map((a) => {
        //   console.log(a?.city, a?.locale, a?.filterTile?._rev);
        // });

        const { modules, ...rest } = allBusaItem;

        const allListicleBlock = listicleBlocks?.filter((a) => {
          const city = a?.city?.trim().toLowerCase()?.replaceAll(' ', '-');
          const matchesCity = city === feedName?.replaceAll('%20', '+');
          const matchesFilterTile = busaFilter ? busaFilter.includes(a?.filterTile?._rev) : true;
          return matchesCity && matchesFilterTile;
        });

        //--- map hero setting to modules array and add page level tags to hero module
        const hideHero = checkValue(allBusaItem?.heroSetting?.hideHero) ? allBusaItem?.heroSetting?.hideHero : false;
        const heroTags = checkValue(allListicleBlock, true)
          ? allListicleBlock
              .map((data) => ({
                prefLabel: data?.filterTile?.title,
              }))
              .slice(0, 3)
          : [];
        const heroModule =
          checkValue(allBusaItem?.heroSetting) && !hideHero
            ? {
                module: allBusaItem?.heroSetting._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...allBusaItem?.heroSetting,
                fixedHeadlineSize: true,
                turnOffBreadcrumbs: true,
                tags: heroTags,
              }
            : null;

        const formattedModules = modules?.map((module) => ({
          module: module?.['_type'],
          ...module,
          dataFeed: FeedType.Sanity,
        }));

        // const heroModule = checkValue(allBatchItem?.heroSetting)
        //   ? { module: allBatchItem?.heroSetting._type, dataFeed: 'sanity', ...allBatchItem?.heroSetting }
        //   : null;

        const finalFormattedData = {
          ...rest,
          content: checkValue(heroModule) ? [heroModule, ...formattedModules] : formattedModules,
          allListicleBlock,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          removeModuleSpacings: true,
          ignoreTextTruncate: true,
          defaultTagByLocalization: true, // to get the basic 1up default tag by localization
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}
