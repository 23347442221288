import { AllBatch1Template } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import getPageTags from '../helpers/getTags';

export class Batch1TemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  public async fetchAllBatch1Template(
    partner: string,
    templateName: string,   
    isDummyData: boolean
  ): Promise<any> {
    const variables: AllBatch1Template.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: templateName,
          },
        },
      },
    };    

    const data = await this.sanityApiSource.allBatch1Template(variables, partner);

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: [...data.data.allBatch1Template, ...sanityDummyData.content],
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(data) && checkValue(data.data) && checkValue(data.data.allBatch1Template)) {
        const allBatchItem = data?.data?.allBatch1Template?.[0];
        const campaign = allBatchItem?.pos;
        const showMarkerIo = allBatchItem?.showMarkerIo;
        const { modules, ...rest } = allBatchItem;

        const formattedModules = checkValue(modules, true)
          ? allBatchItem?.modules?.map((module) => ({
              module: module._type,
              ...module,
              dataFeed: FeedType.Sanity,
            }))
          : [];

        const hideHero = checkValue(allBatchItem?.heroSetting?.hideHero) ? allBatchItem?.heroSetting?.hideHero : false;
        const heroTags = getPageTags(allBatchItem?.tags?.tag);
        // const heroTags = [{prefLabel:"Beach"} , {prefLabel:"Nature"}]
        const heroModule =
          checkValue(allBatchItem?.heroSetting) && !hideHero
            ? {
                module: allBatchItem?.heroSetting._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...allBatchItem?.heroSetting,
                tags: heroTags,
              }
            : null;

        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...formattedModules] : formattedModules,
          showMarkerIo,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          ignoreTextTruncate: true,
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}