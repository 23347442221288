export interface pageDataProps {
  partner: string;
  templateName: string;
  feedName: string;
}
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const getPageData = (props: pageDataProps) => {
  //   const { partner, templateName, feedName } = props;
  const pageName = 'Studio landing';
  return {
    navigationId: 'hotel',
    pageId: `${pageName},H,10`,
    pageName: pageName,
    clickstreamPageName: pageName,
    clickstreamPageNameDetailed: pageName + ' loaded',
  };
};
