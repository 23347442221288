import { Request } from 'bernie-http';
import { checkValue } from 'src/components/functions';

export const sanityUrls = [
  {
    campaignPartner: 'see',
    campaignTemplate: 'visitUSA',
    BatchQueue: 'busa',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'magazine',
    BatchQueue: 'magazine',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'best-time-to-book-en-us',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'best-time-to-book-en-gb',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'best-time-to-book-en-au',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'meilleurs-moments-pour-reserver',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'beste-zeit-zu-buchen',
    BatchQueue: 'btb',
  },
];

const checkIsSanityData = (templateName) => {
  // Magazine Template
  // magazine --> /magazine"  | /tempmagazine | see/magazine | see/tempmagazine
  // magazine --> /magazine/abc" | /tempmagazine/abc"
  if (templateName?.toLowerCase().startsWith('magazine')) {
    return [
      {
        campaignPartner: 'see',
        campaignTemplate: 'magazine',
        BatchQueue: 'magazine',
      },
    ];
  }

  const isByPassSanity = sanityUrls.filter((u) => {
    return u?.campaignTemplate?.toLowerCase() === templateName.toLowerCase();
  });

  return isByPassSanity;
};

export const routeMapper = (partner, templateName, feedName, query: Request['query']) => {
  const { dummyData, batchQuery, filter, sanity } = query || {};

  const isByPassSanity = checkIsSanityData(templateName);
  const isSanityData = checkValue(isByPassSanity, true) || sanity === '1';

  const isSanityDummyData = isSanityData && dummyData === '1';
  const finalBatchQuery = checkValue(isByPassSanity, true)
    ? isByPassSanity?.[0]?.BatchQueue
    : checkValue(batchQuery)
    ? batchQuery
    : 'b1';
  const finalBusaFilter = filter ?? '';

  return {
    isSanityData,
    isSanityDummyData,
    batchQuery: finalBatchQuery,
    busaFilter: finalBusaFilter,
  };
};
