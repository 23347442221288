import { AllBatch3Template } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import getPageTags from '../helpers/getTags';

export class Batch3TemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  public async fetchAllBatch3Template(
    partner: string,
    templateName: string,
    isDummyData: boolean
  ): Promise<any> {
    const variables: AllBatch3Template.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: templateName,
          },
        },
      },
    };

    const data = await this.sanityApiSource.allBatch3Template(variables, partner);

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: [...data.data.allBatch3Template, ...sanityDummyData.content],
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(data) && checkValue(data.data) && checkValue(data.data.allBatch3Template)) {
        const allBatchItem = data?.data?.allBatch3Template?.[0];
        const campaign = allBatchItem?.pos;
        // const heroThemeTags = checkValue(allBatchItem?.tags?.theme, true) ? allBatchItem?.tags?.theme : [];
        // const heroGeoTags = checkValue(allBatchItem?.tags?.geo, true) ? allBatchItem?.tags?.geo : [];
        // const heroTags = [...heroThemeTags, ...heroGeoTags];
        const heroTags = getPageTags(allBatchItem?.tags?.tag)
        const { modules, ...rest } = allBatchItem;

        const formattedModules = modules?.map((module) => ({
          module: module?.['_type'],
          ...module,
          ...(module?.['_type'] === 'liveEventHero' ? { tags: heroTags } : {}),
          dataFeed: FeedType.Sanity,
        }));

        // const heroModule = checkValue(allBatchItem?.heroSetting)
        //   ? { module: allBatchItem?.heroSetting._type, dataFeed: 'sanity', ...allBatchItem?.heroSetting }
        //   : null;
        const finalFormattedData = {
          ...rest,
          ...campaign,
          tags: {...allBatchItem?.tags , tag:getPageTags(allBatchItem?.tags?.tag)},
          content: formattedModules,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          ignoreTextTruncate: true,
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}
