import v3Seo from './seo';
// import v3TestBrandModuleData from '../v3-test-brand-module/data/v3-test-brand-module.data';
// import {
//   v3BreakerGalleryModule,
//   v3BreakerGalleryModule2,
//   v3BreakerGalleryModule3,
//   v3BreakerGalleryModule4,
// } from '../v3-breaker-gallery-module/data/v3-breaker-gallery-module.data';
// import {
//   v3BreakerQuotePrimaryData,
//   v3BreakerQuoteSecondaryData,
//   v3BreakerQuoteTeritaryData,
// } from '../v3-breaker-quote/data/v3-breaker-quote-module.data';
// import {
//   v3BreakerBioPrimaryModuleData,
//   v3BreakerBioSecondaryModuleData,
//   v3BreakerBioTertiaryModuleData,
// } from '../v3-breaker-bio/data/v3-breaker-bio-module.data';
// import v3TestInlineImageModuleData from '../v3-inline-image-module/data/v3-test-inline-image-module-data';
// import v3ListicleBlockModuleData from '../v3-listicle-block-module/data/v3-test-listicle-block-module-data';
// import {
//   v3MagazineNameplate,
//   v3MagazineNameplateV2,
//   v3MagazineNameplateV3,
//   v3MagazineNameplateV4,
//   v3MagazineNameplateV5,
//   v3MagazineNameplateV6,
//   v3MagazineNameplateV7,
//   v3MagazineNameplateV8,
//   v3MagazineNameplateV9,
// } from '../v3-magazine-nameplate/data/v3-magazine-nameplate-brand-module.data';
// import {
//   articleHero_LeftImage_Max_UAT,
//   articleHero_LeftImage_Max_OnekeyPrimary_UAT,
//   articleHero_LeftImage_Max_OnekeySecondary_UAT,
//   articleHero_LeftImage_Max_Secondary_UAT,
//   articleHero_LeftImage_Max_Tertiary_UAT,
//   articleHero_LeftImage_Min_UAT,
//   articleHero_TopImage_Max_UAT,
//   articleHero_TopImage_Min_UAT,
// } from '../v3-brand-artcile-hero-module/data/article-hero-data';
// import {
//   v3BreakerCollage2upModuleData,
//   v3BreakerCollage3upModuleData,
// } from '../v3-breaker-collage/data/v3-breaker-collage-module.data';
// import v3BreakerVideoModuleData from 'components/common/modules/v3/brand/v3-breaker-video-module/data/v3-breaker-video-module.data';
// import v3AreaTabbedListModuleData from '../v3-area-tabbed-list-module/data/v3-area-tabbed-list-module.data';
// import {v3CoverPackageModuleData, v3CoverPackageModuleData2, v3CoverPackageModuleData3} from '../v3-cover-package-module/data/v3-cover-package-module.data';
// import v3BreakerRichCarouselModule from 'components/common/modules/v3/brand/v3-breaker-rich-carousel-module/data/v3-breaker-rich-carousel-module.data';
// import {breakerImagesDataPrimary1, breakerImagesDataPrimary2, breakerImagesDataPrimary3, breakerImagesDataSecondary, breakerImagesDataTertiary} from 'components/common/modules/v3/brand/v3-breaker-image-batch2/data/v3-breaker-image-batch2.data';
// import {fourUpCardCarouselData1, fourUpCardCarouselData2, fourUpCardCarouselData3, fourUpCardCarouselData4, fourUpCardCarouselData5} from 'components/common/modules/v3/brand/v3-four-up-card-carousel-module/data/data';

// import {
//   v3breakerAppDownloadData,
//   v3breakerAppDownloadData2,
//   v3breakerAppDownloadData3,
// } from '../v3-breaker-app-download/data/v3-breaker-app-download.data';
// import { v3ImageHeroModuleData } from '../v3-brand-image-hero-module/data';
// import { v3VideoHeroData } from '../v3-brand-video-hero-module/data';
// import {v3BreakerTravelWidget} from '../v3-breaker-booking-widget/data/v3-breaker-booking-widget.data';

// import { v3DiscoverMoreData } from '../v3-discover-more-module/data';
// import V3ThreeUpCardsModuleData from '../v3-three-cards-module/data/v3-three-cards-module-data';
// import { v3FilterModuleData } from '../v3-filter-module/data/v3-filter-module.data';
// import v3SubBrandBanner from '../v3-sub-brand-banner/data/v3-sub-brand-banner-module.data';

// import { v3Feature1UpW2Images, v3Feature1UpW2Images2, v3Feature1UpW2Images3, v3Feature1UpW2Images4 } from '../v3-feature-one-up-w-two-images/data/v3-feature-one-up-w-two-images.data';

// import { editorialFeature1Up1 } from '../v3-editorial-feature1-up/data/v3-editorial-feature1-up.data';
// import {
//   v3ListModuleDataV1,
//   v3ListModuleDataV2,
//   v3ListModuleDataV3,
//   v3ListModuleDataV4,
//   v3ListModuleDataV5,
//   v3ListModuleDataV6,
//   v3ListModuleDataV7,
//   v3ListModuleDataV8,
//   v3ListModuleDataV9,
//   v3ListModuleDataV10,
//   v3ListModuleDataV11,
//   v3ListModuleDataV12,
//   v3ListModuleDataV13,
//   v3ListModuleDataV14,
//   v3ListModuleDataV15,
//   v3ListModuleDataV16,
//   v3ListModuleDataV17,
//   v3ListModuleDataV18,
//   v3ListModuleDataV19,
//   v3ListModuleDataV20,
//   v3ListModuleDataV21,
// } from '../v3-lists/data/v3-lists.data';
// import { v3BreakerPromo } from '../v3-breaker-promo/data/v3-breaker-promo.data';
// import {
//   basic1Up1,
//   basic1Up2,
//   basic1Up3,
//   basic1Up4,
//   basic1Up5,
//   basic1Up6,
//   basic1Up7,
//   basic1Up8,
//   basic1Up9,
//   basic1Up10,
//   basic1Up11,
// } from '../v3-basic1-up-module/data/v3-basic1-up-module.data';
// import {
//   v3LiveEventHeroData1,
//   v3LiveEventHeroData2,
//   v3LiveEventHeroData3,
//   v3LiveEventHeroData4,
//   v3LiveEventHeroData5,
//   v3LiveEventHeroData6,
// } from '../v3-brand-live-event-hero-module/data/index';
// import { v3Highlight3Up, v3Highlight3Up2} from '../v3-highlight-three-up/data/v3-highlight-three-up.data';
// import v3BreakerCompetitionModuleData from "../v3-breaker-competition-module/data/v3-breaker-competition-module.data";
// import v3SponsoredContentHeroModuleData from '../v3-sponsored-content-hero-module/data/v3-sponsored-content-hero-module.data';
// import { v3FixturePlannerModuleData, v3FixturePlannerModuleDataV2, v3FixturePlannerModuleDataV3 } from '../v3-fixture-planner-module/data/v3-fixture-planner-module.data';
import {
  v3AdUnitsData,
  v3AdUnitsData1,
  v3AdUnitsData2,
  v3AdUnitsData3,
  v3AdUnitsData4,
  v3AdUnitsData5,
  v3AdUnitsData6,
  v3AdUnitsData7,
  v3AdUnitsData8,
} from '../v3-ad-units/data/v3-ad-units.data';
// import { v3HotelDeals } from '../v3-brand-hotel-deals-module/data/v3-brand-hotel-deals-module.data';
// import {v3TestimonialCarouselModuleData}
//   from "components/common/modules/v3/brand/v3-testimonial-carousel/data/data";
// import v3EditorialFeature1Up from '../v3-editorial-feature1-up';
// import {
//   v3InlineAccordian,
//   v3InlineAccordian2,
//   v3InlineAccordian3,
//   v3InlineAccordian4,
// } from '../v3-inline-accordion/data/v3-inline-accordian-data';

const data = {
  seo: v3Seo,
  content: [
    // { ...v3InlineAccordian },
    // { ...v3InlineAccordian2 },
    // { ...v3InlineAccordian3 },
    // { ...v3InlineAccordian4 },
    // { ...v3LiveEventHeroData1 },
    // { ...v3LiveEventHeroData2 },
    // { ...v3LiveEventHeroData3 },
    // { ...v3LiveEventHeroData4 },
    // { ...v3LiveEventHeroData5 },
    // { ...v3LiveEventHeroData6 },
    // { ...articleHero_LeftImage_Max_UAT },
    // { ...articleHero_LeftImage_Max_OnekeyPrimary_UAT },
    // { ...articleHero_LeftImage_Max_OnekeySecondary_UAT },
    // { ...articleHero_LeftImage_Max_Secondary_UAT },
    // { ...articleHero_LeftImage_Max_Tertiary_UAT },
    // { ...articleHero_LeftImage_Min_UAT },
    // { ...articleHero_TopImage_Max_UAT },
    // { ...articleHero_TopImage_Min_UAT },
    // { ...v3breakerAppDownloadData },
    // { ...v3breakerAppDownloadData2 },
    // { ...v3breakerAppDownloadData3 },
    // { ...v3VideoHeroData },
    // { ...v3DiscoverMoreData },
    // { ...v3ImageHeroModuleData },
    // { ...v3HotelDeals },
    { ...v3AdUnitsData },
    { ...v3AdUnitsData1 },
    { ...v3AdUnitsData2 },
    { ...v3AdUnitsData3 },
    { ...v3AdUnitsData4 },
    { ...v3AdUnitsData5 },
    { ...v3AdUnitsData6 },
    { ...v3AdUnitsData7 },
    { ...v3AdUnitsData8 },
    // { ...v3breakerAppDownloadData },
    // { ...v3breakerAppDownloadData2 },
    // { ...v3breakerAppDownloadData3 },
    // { ...v3VideoHeroData },
    // { ...v3DiscoverMoreData },
    // { ...v3ImageHeroModuleData },
    // { ...v3TestBrandModuleData },
    // { ...v3MagazineNameplate },
    // { ...v3MagazineNameplateV2 },
    // { ...v3MagazineNameplateV3 },
    // { ...v3MagazineNameplateV4 },
    // { ...v3MagazineNameplateV5 },
    // { ...v3MagazineNameplateV6 },
    // { ...v3MagazineNameplateV7 },
    // { ...v3MagazineNameplateV8 },
    // { ...v3MagazineNameplateV9 },
    // { ...v3TestInlineImageModuleData },
    // { ...v3ListicleBlockModuleData },
    // { ...v3BreakerQuotePrimaryData },
    // { ...v3BreakerQuoteSecondaryData },
    // { ...v3BreakerQuoteTeritaryData },
    // { ...v3BreakerBioPrimaryModuleData },
    // { ...v3BreakerBioSecondaryModuleData },
    // { ...v3BreakerBioTertiaryModuleData },
    // { ...v3BreakerCollage2upModuleData },
    // { ...v3BreakerCollage3upModuleData },
    // { ...v3BreakerVideoModuleData },
    // { ...v3BreakerGalleryModule },
    // { ...v3BreakerGalleryModule2 },
    // { ...v3BreakerGalleryModule3 },
    // { ...v3BreakerGalleryModule4 },
    // { ...v3BreakerRichCarouselModule },
    // { ...v3AreaTabbedListModuleData },
    // { ...v3breakerImageBatch2 },
    // { ...breakerImagesDataPrimary1 },
    // { ...breakerImagesDataPrimary2 },
    // { ...breakerImagesDataPrimary3 },
    // { ...breakerImagesDataSecondary },
    // { ...breakerImagesDataTertiary },
    // { ...v3FilterModuleData },
    // { ...breakerImagesDataPrimary1},
    // { ...breakerImagesDataPrimary2},
    // { ...breakerImagesDataPrimary3},
    // { ...breakerImagesDataSecondary},
    // { ...breakerImagesDataTertiary},
    // { ...v3FilterModuleData},
    // { ...v3Feature1UpW2Images},
    // { ...v3Feature1UpW2Images2},
    // { ...v3Feature1UpW2Images3},
    // { ...v3Feature1UpW2Images4},
    // { ...v3BreakerTravelWidget },
    // { ...V3ThreeUpCardsModuleData },
    // { ...fourUpCardCarouselData1 },
    // { ...fourUpCardCarouselData2 },
    // { ...fourUpCardCarouselData3 },
    // { ...fourUpCardCarouselData4 },
    // { ...fourUpCardCarouselData5 },
    // { ...v3CoverPackageModuleData },
    // { ...v3CoverPackageModuleData2 },
    // { ...v3CoverPackageModuleData3 },
    // { ...v3SubBrandBanner },
    // { ...v3EditorialFeature1Up },
    // { ...v3ListModuleDataV1 },
    // { ...v3ListModuleDataV2 },
    // { ...v3ListModuleDataV3 },
    // { ...v3ListModuleDataV4 },
    // { ...v3ListModuleDataV5 },
    // { ...v3ListModuleDataV6 },
    // { ...v3ListModuleDataV7 },
    // { ...v3ListModuleDataV8 },
    // { ...v3ListModuleDataV9 },
    // { ...v3ListModuleDataV10 },
    // { ...v3ListModuleDataV11 },
    // { ...v3ListModuleDataV12 },
    // { ...v3ListModuleDataV13 },
    // { ...v3ListModuleDataV14 },
    // { ...v3ListModuleDataV15 },
    // { ...v3ListModuleDataV16 },
    // { ...v3ListModuleDataV17 },
    // { ...v3ListModuleDataV18 },
    // { ...v3ListModuleDataV19 },
    // { ...v3ListModuleDataV20 },
    // { ...v3ListModuleDataV21 },
    // { ...v3BreakerPromo },
    // { ...basic1Up1 },
    // { ...basic1Up2 },
    // { ...basic1Up3 },
    // { ...basic1Up4 },
    // { ...basic1Up5 },
    // { ...basic1Up6 },
    // { ...basic1Up7 },
    // { ...basic1Up8 },
    // { ...basic1Up9 },
    // { ...basic1Up10 },
    // { ...basic1Up11 },
    // { ...v3Highlight3Up },
    // { ...v3Highlight3Up2 },
    // {...v3BreakerCompetitionModuleData},
    // { ...v3SponsoredContentHeroModuleData },
    // { ...v3TestimonialCarouselModuleData },
    // { ...v3ShoppableOneUpCardCarouselModuleData2 },
    // { ...v3ShoppableOneUpCardCarouselModuleData3 },
    // { ...v3ShoppableOneUpCardCarouselModuleData.v3ShoppableOneUpCardCarouselModuleData2 },
    // { ...v3ShoppableOneUpCardCarouselModuleData.v3ShoppableOneUpCardCarouselModuleData3 },
    // { ...v3FixturePlannerModuleData},
    // { ...v3FixturePlannerModuleDataV2},
    // { ...v3FixturePlannerModuleDataV3},
  ],
};

export default data;
